import React from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/Fade In Section Down To Up/FadeInSectionDownToUp'
import FadeInSectionLeftToRight from '../../Components/FadeAnimations/Fade In Section Left To Right/FadeInSectionLeftToRight'
import { GiEyeTarget } from 'react-icons/gi'
import FadeInSectionRightToLeft from '../../Components/FadeAnimations/Fade In Section Right To Left/FadeInSectionRightToLeft'
import { IoShieldCheckmark } from 'react-icons/io5'
import { FaHandshake, FaLeaf, FaLightbulb, FaStar } from 'react-icons/fa'

export default function AboutUs() {
    return (
        <div>
            {/* <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MH%20Agro/About%20Us/MH%20Agro.webp' alt='MH Agro' />
            </div> */}
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/About-Us/Our%20Values%20New1.mp4.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='py-20 max-sm:py-10'>
                <FadeInSectionDownToUp>
                    <h1 className='font-bold text-5xl text-center py-2'>Who We Are</h1>
                    <p className='text-center text-2xl'>Innovating <span className='text-[#0fe934]'>Agriculture</span> with Technology</p>
                    <p className='text-justify text-lg px-80 xl:px-80 sm:px-20 max-sm:px-5 pt-5'>
                        MH Agriculture revolutionizes the agricultural industry with advanced technology solutions that boost productivity and profitability. We empower farmers, optimize crop yields, and promote sustainable practices. Our innovative tools, like drone technology and smart irrigation systems, help farmers save time, reduce costs, and increase efficiency. We are committed to driving positive change in agriculture, ensuring a prosperous future for farmers globally.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-14 max-sm:px-5 py-10'>
                <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-0'>
                    <div>
                        <FadeInSectionLeftToRight>
                            <div className='flex justify-center items-center py-5'>
                                <GiEyeTarget size={40} />
                            </div>
                            <h1 className='text-xl font-bold'>Our Vision</h1>
                            <p className='text-lg text-[#0fe934] font-semibold py-2'>Transforming Farms with Innovation, Empowering Farmers for a Sustainable Future.</p>
                            <p className='text-justify'>
                                MH Agriculture, our vision is to revolutionize the agricultural landscape with innovative technology solutions that empower farmers and enhance productivity. We aim to be the leading provider of cutting-edge agricultural technology, driving sustainable farming practices for a better future.
                            </p>
                        </FadeInSectionLeftToRight>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <div className='flex justify-center items-center py-5'>
                                <IoShieldCheckmark size={40} />
                            </div>
                            <h1 className='text-xl font-bold'>Our Mission</h1>
                            <p className='text-lg text-[#0fe934] font-semibold py-2'>Advancing Agriculture through Technology, Securing a Prosperous Future for Farmers.</p>
                            <p className='text-justify'>
                                MH Agro Our Mission is to provide farmers with advanced technological tools and support, enabling them to optimize their operations, increase yields, and reduce environmental impact. We are committed to fostering innovation, delivering high-quality solutions, and promoting sustainable agricultural practices.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div>
                        <FadeInSectionRightToLeft>
                            <div className='flex justify-center items-center py-5'>
                                <FaLightbulb size={40} />
                            </div>
                            <h1 className='text-xl font-bold'>Our Values</h1>
                            <p className='text-lg text-[#0fe934] font-semibold py-2'>Empowering Farmers with Innovative Solutions for a Sustainable Tomorrow.</p>
                            <p className='text-justify'>
                                At MH Agriculture, our values drive our commitment to innovation, empowerment, sustainability, and integrity. We prioritize excellence in delivering high-quality solutions tailored to meet the unique needs of farmers worldwide. Through responsible practices and a global impact mindset, we aim to revolutionize agriculture while ensuring a prosperous future for all stakeholders
                            </p>
                        </FadeInSectionRightToLeft>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-20 max-sm:px-10 py-10'>
                <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-8'>
                    <FadeInSectionDownToUp>
                        <div className='flex-row justify-center items-center p-5 rounded-xl shadow-xl group'>
                            <center className='space-y-3'>
                                <div className='group-hover:scale-125 transition-transform duration-700 ease-in-out'>
                                    <FaLightbulb size={40} />
                                </div>
                                <p>Cutting-Edge Solutions</p>
                            </center>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className='flex-row justify-center items-center p-5 rounded-xl shadow-xl group'>
                            <center className='space-y-3'>
                                <div className='group-hover:scale-125 transition-transform duration-700 ease-in-out'>
                                    <FaLeaf size={40} />
                                </div>
                                <p>Environmental Stewardship</p>
                            </center>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className='flex-row justify-center items-center p-5 rounded-xl shadow-xl group'>
                            <center className='space-y-3'>
                                <div className='group-hover:scale-125 transition-transform duration-700 ease-in-out'>
                                    <FaHandshake size={40} />
                                </div>
                                <p>Trust & Transparency</p>
                            </center>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className='flex-row justify-center items-center p-5 rounded-xl shadow-xl group'>
                            <center className='space-y-3'>
                                <div className='group-hover:scale-125 transition-transform duration-700 ease-in-out'>
                                    <FaStar size={40} />
                                </div>
                                <p>Exceptional Quality</p>
                            </center>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='py-20 max-sm:py-10'>
                <FadeInSectionDownToUp>
                    <h1 className='text-5xl text-center py-2'>What We Do</h1>
                    <p className='text-justify text-lg px-80 xl:px-80 sm:px-20 max-sm:px-5 pt-5'>
                        At MH Agro, we revolutionize <span className='text-[#0fe934]'>Agriculture</span> through advanced technology and innovative solutions. Our mission is to enhance farming efficiency, boost productivity, and promote sustainability. By providing farmers with cutting-edge tools and support, we help optimize operations, increase yields, and ensure a prosperous future for the agricultural industry.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-20 max-sm:py-10'>
                <FadeInSectionDownToUp>
                    <h1 className='text-5xl text-center py-2'>Our Solutions</h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center px-20 max-sm:px-10 py-10 max-sm:py-5 xl:space-y-0 sm:space-y-5 max-sm:space-y-5'>
                <div className='flex justify-center items-center'>
                    <iframe className='rounded-xl shadow-xl max-sm:w-full' width="560" height="315" src="https://www.youtube.com/embed/SU3CV0ocmYI?si=awaiBlu4ipDJRMmV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='p-14 shadow-xl mx-10 max-sm:mx-0 border rounded-xl'>
                    <FadeInSectionDownToUp>
                        <p className='text-2xl font-semibold py-2'>
                            Drone Technology for Precision Farming
                        </p>
                        <p>
                            We provide advanced drones for precision farming, using real-time sensors and imaging to monitor crops and soil. This helps optimize inputs, increase yields, and promote sustainable farming.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex xl:flex-nowrap sm:flex-wrap-reverse max-sm:flex-wrap-reverse justify-center items-center px-20 max-sm:px-10 py-10'>
                <div className='p-14 shadow-xl mx-10 max-sm:mx-0 border rounded-xl xl:my-0 sm:my-5 max-sm:my-5'>
                    <FadeInSectionDownToUp>
                        <p className='text-2xl font-semibold py-2'>
                            Drone Technology for Precision Farming
                        </p>
                        <p>
                            We provide advanced drones for precision farming, using real-time sensors and imaging to monitor crops and soil. This helps optimize inputs, increase yields, and promote sustainable farming.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex justify-center items-center'>
                    <iframe className='rounded-xl shadow-xl max-sm:w-full' width="560" height="315" src="https://www.youtube.com/embed/1QH7P-daRNU?si=0pi2FS3ZxSkNMGRp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <div className='flex xl:flex sm:block max-sm:block justify-center items-center px-20 max-sm:px-10 py-10 max-sm:py-5 xl:space-y-0 sm:space-y-5 max-sm:space-y-5'>
                <div className='flex justify-center items-center'>
                    <iframe className='rounded-xl shadow-xl max-sm:w-full' width="560" height="315" src="https://www.youtube.com/embed/Lkzk_CYNEKo?si=q-96U9CmRB_i3GDN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='p-14 shadow-xl mx-10 max-sm:mx-0 border rounded-xl'>
                    <FadeInSectionDownToUp>
                        <p className='text-2xl font-semibold py-2'>
                            Drone Technology for Precision Farming
                        </p>
                        <p>
                            We provide advanced drones for precision farming, using real-time sensors and imaging to monitor crops and soil. This helps optimize inputs, increase yields, and promote sustainable farming.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
