import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0d0e0ef2] bg-opacity-90 py-5 text-light">
      <div className="container mx-auto">
        <div className="flex flex-wrap px-20 xl:px-20 sm:px-10 max-sm:px-5">
          <div className="w-full md:w-1/3 mb-4">
            <img src="https://ik.imagekit.io/mhcockpit1/MH%20Agro/LOGO/logo-light%201.png" alt="Logo" className="w-24 h-auto mb-3" />
            <address className="text-white">
              <h5 className="text-lg mb-2">Tower A, Featherlite The Address</h5>
              <p>Chennai, Tamil Nadu 600043</p>
              {/* <p>Email: info@mhagro.com</p> */}
              <p>Phone: +91 9884927480</p>
            </address>
          </div>
          <div className="w-full md:w-1/3 mb-4">
            <h5 className="text-lg mb-2 text-white">Quick Links</h5>
            <ul className="list-none">
              <li><a href="/home" className="text-gray-300 hover:underline">Home</a></li>
              <li><a href="/about" className="text-gray-300 hover:underline">About Us</a></li>
              <li><a href="/services" className="text-gray-300 hover:underline">Services</a></li>
              <li><a href="/contact" className="text-gray-300 hover:underline">Contact Us</a></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 mb-4">
            <h5 className="text-lg mb-2 text-white">Social Media</h5>
            <div className="flex">
              <a href="https://www.facebook.com" className="text-gray-300 hover:text-yellow-400 me-4"><FaFacebook /></a>
              <a href="https://www.twitter.com" className="text-gray-300 hover:text-yellow-400 me-4"><FaTwitter /></a>
              <a href="https://www.linkedin.com" className="text-gray-300 hover:text-yellow-400 me-4"><FaLinkedin /></a>
              <a href="https://www.instagram.com" className="text-gray-300 hover:text-yellow-400"><FaInstagram /></a>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <p className="text-center text-gray-300">&copy; {currentYear} MH Agro. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
